import React from "react";
import Carousel from "react-bootstrap/Carousel";
import carousel1 from "../../assets/Home/carousel/carousel1.png";
import carousel2 from "../../assets/Home/carousel/carousel2.png";
import carousel3 from "../../assets/Home/carousel/carousel3.png";
const HomeCarousel = () => {
  return (
    <Carousel indicators={false} className="home-carousel">
      <Carousel.Item>
        <img className="d-block w-100" src={carousel3} alt="Micro Game" />
        <Carousel.Caption>
          <h3>The Micro Business Game</h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="d-block w-100" src={carousel2} alt="Savings Game" />
        <Carousel.Caption>
          <h3>The Savings Game</h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="d-block w-100" src={carousel1} alt="Eco Egypt" />
        <Carousel.Caption>
          <h3 className="">Eco Egypt</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
