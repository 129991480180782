import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { contentList, impactProjects } from "./contentList";
import Carousel from "react-bootstrap/Carousel";

import "./singleWork.scss";
import AnimatedPages from "../../Animation/AnimatedPages";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SingleWork = () => {
  const [project, setProject] = useState(contentList[2]);
  const { child } = useParams();
  let history = useHistory();
  let query = useQuery();
  let impact = query.get("impact");
  const projectNumber = Number(child);

  useEffect(() => {
    if (impact) {
      if (impactProjects[projectNumber] !== undefined) {
        setProject(impactProjects[projectNumber]);
      } else {
        history.push("/our-work/0?impact=true");
      }
    } else {
      if (contentList[projectNumber] !== undefined) {
        setProject(contentList[projectNumber]);
      } else {
        history.push("/our-work/0");
      }
    }
  }, [impact, projectNumber, history]);

  return (
    <AnimatedPages>
      <div className="container ">
        <div className="row">
          <div className="col-lg-12 ">
            <div className="container single-work">
              <h1 className="project-title">{project.projectName}</h1>
              <div className="row align-items-start text-left">
                <div className="col-lg-4 col-12">
                  <div className="header mt-3">
                    <h2 className="partners">
                      <span className="bold">Partners:</span> {project.partners}
                    </h2>
                    <h3 className="project-type">
                      <span className="bold">Type: </span> {project.type.text}{" "}
                      {project.type.icon.icon1 ? (
                        <span className="mx-1 icon-header">
                          <i className={project.type.icon.icon1}></i>
                        </span>
                      ) : (
                        ""
                      )}
                      {project.type.icon.icon2 ? (
                        <span className="mx-1 icon-header">
                          <i className={project.type.icon.icon2}></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                  <Carousel
                    className="col-12 d-lg-none d-block"
                    variant="dark"
                    indicators={false}
                  >
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={project.carousel.images[0]}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={project.carousel.images[1]}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                  </Carousel>

                  {project.projectDescription ? (
                    <div className="challenge my-3">
                      <h5>Project Description</h5>
                      <p>{project.projectDescription}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {project.challenge ? (
                    <div className="challenge my-3">
                      <h5>Challenge</h5>
                      <p>{project.challenge}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {project.approach ? (
                    <div className="challenge my-3">
                      <h5>Approach</h5>
                      <p>{project.approach.text}</p>
                      <ul className="ps-4">
                        {project.approach.points
                          ? project.approach.points.map((point, index) => (
                              <li key={index}>{point}</li>
                            ))
                          : ""}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {project.otherBenefits ? (
                    <div className="challenge my-3">
                      <h5>Other Benefits</h5>
                      <ul className="">
                        {project.otherBenefits.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="technologies mt-3">
                    <h5 className="mb-3">Technologies</h5>
                    <div className="icons row m-0 ">
                      {project.technologies.map((technology, index) => (
                        <div
                          key={index}
                          className="col-3 row m-0 p-0 text-center"
                        >
                          <div style={{ minHeight: "65px" }}>
                            <img
                              className="img-fluid"
                              src={technology.image}
                              alt={technology.name}
                            />
                          </div>
                          <div className="">{technology.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <Carousel
                  className="col-lg-8 col-12 d-none d-lg-block"
                  variant="dark"
                  indicators={false}
                >
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={project.carousel.images[0]}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={project.carousel.images[1]}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                </Carousel>

                <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                  <div className="my-4 col-md-4 col-12 p-0 ">
                    {project.url ? (
                      <a
                        href={project.url}
                        className="visit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    ) : (
                      ""
                    )}
                    <div className="row justify-content-between mt-4">
                      {project.googlePlayUrl ? (
                        <div className="col-6 pe-1">
                          <a
                            href={project.googlePlayUrl.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img
                              className="img-fluid"
                              src={project.googlePlayUrl.img}
                              alt="googlePlayUrl"
                            />
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {project.appStoreUrl ? (
                        <div className="col-6 pe-1">
                          <a
                            href={project.appStoreUrl.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img
                              className="img-fluid h-100"
                              src={project.appStoreUrl.img}
                              alt="appStoreUrl"
                            />
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Link
                    to={
                      impact
                        ? `/our-work/${projectNumber + 1}?impact=true`
                        : `/our-work/${projectNumber + 1}`
                    }
                    className="nextPro col-md-4 col-12 d-flex flex-wrap justify-content-end align-items-end"
                  >
                    <h6>Next Project</h6>
                    <span className="ms-2">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default SingleWork;
