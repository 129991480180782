import React, { useState } from "react";

const Card = ({ title, content, image }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4">
      <div
        className="row card m-0 py-4 pt-5 text-center shadow"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div className="col-12 p-0 img text-center">
          <img
            className="img-fluid"
            src={hover ? image.imgLight : image.img}
            alt={""}
          />
        </div>
        <div className="">
          <h5 className="card-title mt-2 ">{title}</h5>
          <div className="my-2 content-wrap d-flex align-items-center justify-content-center">
            <p className="card-text px-4 text-white">{content}</p>
            <p className="card-text-hover">Hover to know more</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
