import Savings1 from "../../../assets/our work/carousel/Savings1.png";
import Savings2 from "../../../assets/our work/carousel/Savings2.png";
import Microbusiness1 from "../../../assets/our work/carousel/Microbusiness1.png";
import Microbusiness2 from "../../../assets/our work/carousel/Microbusiness2.png";
import Patrec1 from "../../../assets/our work/carousel/Patrec1.png";
import Patrec2 from "../../../assets/our work/carousel/Patrec2.png";
import Eco1 from "../../../assets/our work/carousel/Eco1.png";
import Eco2 from "../../../assets/our work/carousel/Eco2.png";
import SIMTalent1 from "../../../assets/our work/carousel/SIMTalent1.png";
import SIMTalent2 from "../../../assets/our work/carousel/SIMTalent2.png";
import ApplesOrangesMockup1 from "../../../assets/our work/carousel/ApplesOrangesMockup1.png";
import ApplesOrangesMockup2 from "../../../assets/our work/carousel/ApplesOrangesMockup2.png";
// tecnologies
import autodesk from "../../../assets/our work/technologies/savingTecnolog/autodesk.png";
import blender from "../../../assets/our work/technologies/savingTecnolog/blender.png";
import unity from "../../../assets/our work/technologies/savingTecnolog/unity.png";

import swift from "../../../assets/our work/technologies/patric/swift.png";
import android from "../../../assets/our work/technologies/patric/android.png";
import laravel from "../../../assets/our work/technologies/patric/laravel.png";
import react from "../../../assets/our work/technologies/patric/react.png";
import node from "../../../assets/our work/technologies/patric/node.png";
import googlePlay from "../../../assets/our work/googlePlay.png";
import AppStore from "../../../assets/our work/AppStore.png";
export const contentList = [
  // 1
  {
    projectName: "The Savings Game",
    partners:
      "Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    type: {
      text: "Educational Mobile Game",
      icon: {
        icon1: null,
        icon2: "fas fa-mobile-alt",
      },
    },
    projectDescription: null,
    challenge: `The organisation is struggling with the limitation of a closed room gamified training that teaches how to improve one's quality of life and manage finances.`,
    approach: {
      text: `Designing a single player simulation game that injects the learning objectives into an interactive experience.`,
      points: [
        "Empowering on-ground training by the extra activity that is available to the audience.",
        "Extending and strengthening the post-training connection with the audience",
        "Reaching a wider audience and making DSIK’s learning objectives available to a broader target group.",
      ],
    },
    otherBenefits: null,
    technologies: [
      { image: unity, name: "Unity" },
      { image: blender, name: "Blender" },
      { image: autodesk, name: "Autodesk Maya" },
    ],
    carousel: {
      images: [Savings1, Savings2],
    },
    googlePlayUrl: {
      img: googlePlay,
      url: "https://play.google.com/store/apps/details?id=de.sparkasse.savingsgame&gl=US",
    },
    appStoreUrl: {
      img: AppStore,
      url: "https://apps.apple.com/eg/app/the-savings-game/id1583366017",
    },
    url: null,
  },
  // 2
  {
    projectName: "The Micro Business Game",
    partners:
      "Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    type: {
      text: "Educational Mobile Game",
      icon: {
        icon1: null,
        icon2: "fas fa-mobile-alt",
      },
    },
    projectDescription: null,
    challenge: `The organisation is struggling with the limitation of a closed room gamified training that teaches how to run a micro business.`,
    approach: {
      text: `Designing a single player simulation game that injects the learning objectives into an interactive experience.`,
      points: [
        "Empowering on-ground training by the extra activity that is available to the audience.",
        "Extending and strengthening the post-training connection with the audience",
        "Reaching a wider audience and making DSIK’s learning objectives available to a broader target group.",
      ],
    },
    otherBenefits: null,
    technologies: [
      { image: unity, name: "Unity" },
      { image: blender, name: "Blender" },
      { image: autodesk, name: "Autodesk Maya" },
    ],
    carousel: {
      images: [Microbusiness1, Microbusiness2],
    },
    googlePlayUrl: {
      img: googlePlay,
      url: "https://play.google.com/store/apps/details?id=com.sparkasse.thebusinessgame&gl=US",
    },
    appStoreUrl: {
      img: AppStore,
      url: "https://apps.apple.com/eg/app/the-micro-business-game/id6443647722",
    },
    url: null,
  },
  // 3
  {
    projectName: "PatRec",
    partners: "Holden Knight Healthcare",
    type: {
      text: "Platform",
      icon: {
        icon1: "fas fa-desktop",
        icon2: "fas fa-mobile-alt",
      },
    },
    projectDescription: null,
    challenge: `Holdenknight is a medical recruitment agency that works with high calibre professional medical consultants and hospitals in london, the challenge was to open new business opportunities using the same infrastructure. `,
    approach: {
      text: `Building a software that allows patients around the world to receive medical consultation from medical consultants and have efficient and easy access to London top hospitals.`,
      points: null,
    },
    otherBenefits: [
      "The software enables patients to have their full medical records in one place.",
      "The software offers a one stop solution for efficient communication between patients, hospitals, labs, and medical consultants.",
    ],
    technologies: [
      { image: swift, name: "Swift" },
      { image: android, name: "Android" },
      { image: laravel, name: "Laravel" },
      { image: react, name: "React" },
    ],
    carousel: {
      images: [Patrec1, Patrec2],
      text: "PatRec Doctor",
    },
    googlePlayUrl: null,
    appStoreUrl: null,
    url: "https://holdenknight.com/",
  },
  ///// 4
  {
    projectName: "Eco Egypt",
    partners:
      "UNDP / The Ministry of Environment in Egypt - EEAA/ Wali’s Studio ",
    type: {
      text: "Website, AR experience",
      icon: {
        icon1: "fas fa-desktop",
        icon2: null,
      },
    },
    projectDescription: `ECO EGYPT Experiences is a campaign that aims to reconnect adventurous travellers with Egypt’s countless ecological sites and protected areas.The project is a full documentation of Egyptian national parks and eco sites using Augmented Reality (AR) experience.`,
    challenge: `Prompting natural rediscovery and boosting the importance of ecological conservation documenting the Egyptian national parks.`,
    approach: {
      text: "Designing and implementing a software user experience to adopt the physical haptic board game into an online session.",
      points: null,
    },
    otherBenefits: null,
    technologies: [
      { image: laravel, name: "Laravel" },
      { image: react, name: "React" },
    ],
    carousel: {
      images: [Eco1, Eco2],
    },
    googlePlayUrl: null,
    appStoreUrl: null,
    url: "https://ecoegypt.org/",
  },
  // 5
  {
    projectName: "SIMTalent",
    partners: "CELEMI",
    type: {
      text: "Platform",
      icon: {
        icon1: "fas fa-desktop",
        icon2: null,
      },
    },
    projectDescription: `Simtalent is a corporate training gamified content that teaches HR concepts`,
    challenge: `The limitations of the original physical board game and the expenses and accessibility struggles especially in the time of the Covid-1 penedamic corona to have the physical sessions.`,
    approach: {
      text: `Designing and implementing a software user experience to adopt the physical haptic board game into an online session. `,
      points: null,
    },
    otherBenefits: null,
    technologies: [
      { image: laravel, name: "Laravel" },
      { image: react, name: "React" },
      { image: node, name: "Node Js" },
    ],
    carousel: {
      images: [SIMTalent1, SIMTalent2],
    },
    googlePlayUrl: null,
    appStoreUrl: null,
    url: "https://simdustry.de/en/",
  },
  // 6
  {
    projectName: "Apples and Oranges",
    partners: "CELEMI",
    type: {
      text: "Platform",
      icon: {
        icon1: "fas fa-desktop",
        icon2: null,
      },
    },
    projectDescription: `Apples and Oranges is a haptic board game developed by the company Celemi, its purpose is to teach finance for non financial people in corporations.  environment`,
    challenge: `The limitations of the original physical board game and the expenses and accessibility struggles especially in the time of the Covid-1 penedamic to have the physical sessions.`,
    approach: {
      text: `Designing and implementing a software user experience to adopt the physical haptic board game into an online session.`,
      points: null,
    },
    otherBenefits: null,
    technologies: [
      { image: laravel, name: "Laravel" },
      { image: react, name: "React" },
      { image: node, name: "Node Js" },
    ],
    carousel: {
      images: [ApplesOrangesMockup1, ApplesOrangesMockup2],
    },
    googlePlayUrl: null,
    appStoreUrl: null,
    url: "https://celemi.com/",
  },
];
export const impactProjects = [
  // 1
  {
    projectName: "The Savings Game",
    partners:
      "Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    type: {
      text: "Educational Mobile Game",
      icon: {
        icon1: null,
        icon2: "fas fa-mobile-alt",
      },
    },
    projectDescription: null,
    challenge: `The organisation is struggling with the limitation of a closed room gamified training that teaches how to improve one's quality of life and manage finances.`,
    approach: {
      text: `Designing a single player simulation game that injects the learning objectives into an interactive experience.`,
      points: [
        "Empowering on-ground training by the extra activity that is available to the audience.",
        "Extending and strengthening the post-training connection with the audience",
        "Reaching a wider audience and making DSIK’s learning objectives available to a broader target group.",
      ],
    },
    otherBenefits: null,
    technologies: [
      { image: unity, name: "Unity" },
      { image: blender, name: "Blender" },
      { image: autodesk, name: "Autodesk Maya" },
    ],
    carousel: {
      images: [Savings1, Savings2],
    },
    googlePlayUrl: {
      img: googlePlay,
      url: "https://play.google.com/store/apps/details?id=de.sparkasse.savingsgame&gl=US",
    },
    appStoreUrl: {
      img: AppStore,
      url: "https://apps.apple.com/eg/app/the-savings-game/id1583366017",
    },
    url: null,
  },
  // 2
  {
    projectName: "The Micro Business Game",
    partners:
      "Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    type: {
      text: "Educational Mobile Game",
      icon: {
        icon1: null,
        icon2: "fas fa-mobile-alt",
      },
    },
    projectDescription: null,
    challenge: `The organisation is struggling with the limitation of a closed room gamified training that teaches how to run a micro business.`,
    approach: {
      text: `Designing a single player simulation game that injects the learning objectives into an interactive experience.`,
      points: [
        "Empowering on-ground training by the extra activity that is available to the audience.",
        "Extending and strengthening the post-training connection with the audience",
        "Reaching a wider audience and making DSIK’s learning objectives available to a broader target group.",
      ],
    },
    otherBenefits: null,
    technologies: [
      { image: unity, name: "Unity" },
      { image: blender, name: "Blender" },
      { image: autodesk, name: "Autodesk Maya" },
    ],
    carousel: {
      images: [Microbusiness1, Microbusiness2],
    },
    googlePlayUrl: {
      img: googlePlay,
      url: "https://play.google.com/store/apps/details?id=de.sparkasse.savingsgame&gl=US",
    },
    appStoreUrl: {
      img: AppStore,
      url: "https://apps.apple.com/eg/app/the-savings-game/id1583366017",
    },
    url: "https://holdenknight.com/",
  },
];
