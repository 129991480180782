import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import AnimatedPages from "../Animation/AnimatedPages";
import * as Yup from "yup";
import getInTouch from "../../assets/Home/getInTouch.png";
import axios from "axios";

import "./Contact-Us.scss";
const ContactUs = () => {
  const [initValue] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const Schema = Yup.object({
    email: Yup.string().email().required("Required"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });
  const onFormSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios({
        method: "POST",
        url: "https://api.phantasm.biz/api/v1/contact-us",
        data: values,
      });
      if (response) {
        const toasts = document.getElementById("toasts");
        toasts.style.height = "40px";
        setTimeout(() => {
          toasts.style.height = "0";
        }, 5000);
        resetForm();
      }
    } catch (error) {
      const toastsError = document.getElementById("toast-error");
      toastsError.style.height = "40px";
      setTimeout(() => {
        toastsError.style.height = "0";
      }, 5000);
    }
  };
  return (
    <AnimatedPages>
      <div className="container mt-5  contact-us">
        <div className="row m-0 my-5  align-items-center getInTouch ">
          <div className="col-lg-5 col-12 row m-0 ">
            <div className="col-8 ">
              <img className="img-fluid" src={getInTouch} alt="getInTouch" />
            </div>
            <div>
              <p className="title">Get in Touch</p>
              <p className="number">
                <span className="me-3">
                  <i className="fas fa-phone"></i>
                </span>
                +201221599284
              </p>
              <p className="address d-flex align-items-center ">
                <span className="me-3 icon">
                  <i className="fas fa-map-pin"></i>
                </span>
                <span>
                  <strong>Egypt: </strong>
                  El-Shaikh Rihan, El-Shaikh Abd Allah, Abdeen, Cairo
                  Governorate.
                </span>
              </p>

              <p className="address d-flex align-items-center ">
                <span className="me-3 icon">
                  <i className="fas fa-map-pin"></i>
                </span>
                <span>
                  <strong>Ireland: </strong>
                  The Tara Building: Building 11-15, The Tara, Tara St, Dublin
                  2, Ireland.
                </span>
              </p>

              <p className="social mt-4">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/phantasm-solutions/mycompany/"
                  className="me-4"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/phantasmsolutions"
                  className="me-4"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/phantasmsolutions/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>

          <div className="col-lg-7 col-12 form">
            <Formik
              onSubmit={onFormSubmit}
              validationSchema={Schema}
              initialValues={initValue}
              enableReinitialize
            >
              {({ values, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                  <div>
                    <label className="mb-2" htmlFor="email">
                      Email
                    </label>
                    <br />
                    <Field className="form-control" type="text" name="email" />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="error"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="mb-2" htmlFor="subject">
                      Subject
                    </label>
                    <br />
                    <Field
                      className="form-control"
                      type="text"
                      name="subject"
                    />
                    <ErrorMessage
                      component="div"
                      name="subject"
                      className="error"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="mb-2" htmlFor="message">
                      Message
                    </label>
                    <br />
                    <textarea
                      rows="9"
                      className="form-control"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <ErrorMessage
                      component="div"
                      name="message"
                      className="error"
                    />
                  </div>

                  <div className="mt-3">
                    <button type="submit" className="btn">
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default ContactUs;
