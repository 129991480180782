import React from "react";
import line1 from "../../assets/Home/line1.png";
import line2 from "../../assets/What we do/line2.png";
import setings from "../../assets/What we do/setings.png";

import chess from "../../assets/What we do/chees.png";
import chess2 from "../../assets/What we do/chees2.png";

import lab from "../../assets/What we do/lab.png";
import lab2 from "../../assets/What we do/lab2.png";

import Play from "../../assets/What we do/play.png";
import game2 from "../../assets/What we do/game2.png";

import brain from "../../assets/What we do/brain.png";
import brain2 from "../../assets/What we do/brain2.png";

import phone1 from "../../assets/What we do/phone1.png";
import phone2 from "../../assets/What we do/phone2.png";

import sum1 from "../../assets/What we do/sum1.png";
import sum2 from "../../assets/What we do/sum2.png";

import "./whatWeDo.scss";
import Card from "./Views/Card";
import AnimatedPages from "../Animation/AnimatedPages";
import WhatWeDoSection from "./Views/WhatWeDoSection";
import Education from "./Views/Education";
import Training from "./Views/Training";
import Marketing from "./Views/Marketing";
import Entertainment from "./Views/Entertainment";

const Content = [
  {
    title: "Gamification",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: chess,
      imgLight: chess2,
    },
  },
  {
    title: "Game Design",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: Play,
      imgLight: game2,
    },
  },
  {
    title: "Serious Games",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: brain,
      imgLight: brain2,
    },
  },
  {
    title: "App Development",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: phone1,
      imgLight: phone2,
    },
  },
  {
    title: "UI/UX",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: sum1,
      imgLight: sum2,
    },
  },
  {
    title: "Web Development",
    content: `We are well-versed in a wide variety of front-end and back-end technologices that will fulfill your product requirement effciently`,
    images: {
      img: lab,
      imgLight: lab2,
    },
  },
];
const WhatWeDo = () => {
  return (
    <AnimatedPages>
      <div className="container-fluid p-0 my-4">
        <WhatWeDoSection />

        <div className="divider row m-0 p-0">
          <img className="p-0" src={line1} alt="line1" />
        </div>

        <Education />
        <div className="divider row m-0 p-0">
          <img className="p-0" src={line2} alt="line2" />
        </div>
        <Training />
        <div className="divider row m-0 p-0">
          <img className="p-0" src={line2} alt="line2" />
        </div>
        <Marketing />
        <div className="divider row m-0 p-0">
          <img className="p-0" src={line2} alt="line2" />
        </div>
        <Entertainment />
        <div className="divider row m-0 p-0">
          <img className="p-0" src={line1} alt="line1" />
        </div>

        <div className="container-fluid p-0 my-6 WhatWeDo ">
          <div className="row m-0 align-items-center justify-content-between">
            <div className="col-5 d-none d-lg-block text-center image p-0">
              <img className="img-fluid" src={setings} alt="setings" /> <br />
            </div>
            <div className="col-lg-6 col-12 row m-0">
              <div className="col-12 p-0 stitle d-flex second-responsive-title fw-bold">
                <p className="mb-2 ">Our Services</p>
              </div>
              <div className="col-12 d-lg-none d-block text-center image">
                <img className="img-fluid" src={setings} alt="setings" />
              </div>

              <p className="smale-title mt-4  col-12">
                From game development to software development, we offer a wide
                range of services to suit any of your needs.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row m-0 card-section">
            {Content.map((item, index) => (
              <Card
                title={item.title}
                content={item.content}
                key={index}
                image={item.images}
              />
            ))}
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default WhatWeDo;
