import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import NavLinks, { SideNav } from "./NavLinks/NavLinks";
import "./header.scss";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  let location = useLocation();
  useEffect(() => {
    const navBar = document.getElementById("navBar");
    if (location.pathname === "/") {
      navBar.classList.add("bg-for-home");
      console.log(navBar);
    } else {
      navBar.classList.remove("bg-for-home");
    }
  }, [location]);
  return (
    <div className={`container-fluid NavBar p-0 `} id="navBar">
      <div
        className={`row  align-items-center py-3 m-0 ${
          toggle ? "navBar-white" : ""
        }`}
      >
        <div className="col-2 me-auto">
          <NavLink to="/" className="ms-3">
            <img height={"35px"} src={logo} alt="logo" />
          </NavLink>
        </div>
        <NavLinks toggle={toggle} setToggle={setToggle} />
        <div className="d-none d-lg-flex col-lg-2"></div>
        <div className="position-relative d-xl-none d-lg-none d-xl-flex">
          <div
            className={`dropdowen py-3   ${
              toggle ? "sideNav-height" : "sideNav"
            }`}
          >
            <SideNav toggle={toggle} setToggle={setToggle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
