import React from "react";
import { NavLink } from "react-router-dom";
import image2 from "../../assets/impact game/Group 19651.png";
import image1 from "../../assets/impact game/Group 561.png";
import cat from "../../assets/impact game/Cat Cafe 1.png";
import pic from "../../assets/impact game/pic.png";
import pic2 from "../../assets/impact game/pic2.png";
import line2 from "../../assets/What we do/line2.png";
import savingGame from "./../../assets/our work/1.png";
import microGame from "./../../assets/our work/2.png";
import "./ImpactGame.scss";
import AnimatedPages from "../Animation/AnimatedPages";

const projects = [
  {
    id: "savingGame",
    Image: savingGame,
    name: "The Savings Game",
    text: " Partners: Deutsche Sparkassenstiftung für InternationaleKooperation, DSIK",
    icon1: null,
    icon2: "fas fa-mobile-alt",
    type: "Type: Educational Mobile Game",
    linkTo: `0`,
  },
  {
    id: "microGame",
    Image: microGame,
    name: "The Micro Business Game",
    text: " Partners: Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    icon1: null,
    icon2: "fas fa-mobile-alt",
    type: "Type: Educational Mobile Game",
    linkTo: `1`,
  },
];
const ImpactGame = () => {
  return (
    <AnimatedPages>
      <div className="container-fluid p-0 m-0">
        <div className="container ImpactGame">
          <div className="row m-0 my-6 align-items-center justify-content-between">
            <div className="col-lg-6 col-12  text">
              <p className="maintitle ">Impact</p>
              <p className="title second-responsive-title">
                <span className="me-4 number">1</span>Social Development
              </p>
              <div className="col-12 d-lg-none d-block text-center">
                <img className="img-fluid" src={image1} alt="image1" />
              </div>
              <p className="smale-title mt-4">
                At Phantasm, we take social obligations seriously and strive to
                empower education and spread awareness in different communities.
                Our team of game designers and solution architects utilise
                cutting-edge technology and innovation to help organisations
                become more effective and scale their impact. We believe that
                our focus on quality, creativity, and innovation enables us to
                exceed our clients' expectations and deliver valuable solutions.
                Our dedication to creating positive change in society is
                reflected in everything we do.
              </p>
            </div>

            <div className="col-5  d-none d-lg-block  text-center">
              <img className="img-fluid" src={image1} alt="image1" />
            </div>
          </div>
        </div>

        <div className="divider row my-5 p-0">
          <img className="p-0" src={line2} alt="line1" />
        </div>

        <div className="container ImpactGame">
          <div className="row m-0 my-6 align-items-center justify-content-center">
            <div className="col-md-8 col-12 justify-content-center">
              <p className="midparagraph">
                Collaborating with DSIK and The German ministry of International
                Cooperation with social development in mind
              </p>
              <p
                className="midparagraph2 text-secondary"
                style={{ fontWeight: "500" }}
              >
                We worked to extend the impact of on ground workshops to wider
                audiences in over 50 countries, through transforming the
                curricula into engaging digital games. The gaming solutions
                covers financial literacy, entrepreneurship, sustainability, and
                climate change.
              </p>
            </div>
            <div className="col-6  d-none d-lg-block mt-5 text-center">
              <img className="img-fluid" src={pic2} alt="pic2" />
            </div>
            <div className="col-lg-6 col-12 mt-4 text">
              <img className="img-fluid" src={pic} alt="pic" />
            </div>
          </div>
          <div className="col-12 row m-0 my-6 align-items-stretch justify-content-around">
            {projects.map((project, index) => (
              <div className="col-lg-5  col-12 mt-4" key={index}>
                <div className="card text-center shadow p-3 pt-4">
                  <NavLink
                    to={`/our-work/${project.linkTo}?impact=true`}
                    className="top-card-link"
                  >
                    <img
                      className="card-img-top"
                      src={project.Image}
                      alt={project.id}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{project.name}</h5>
                      <div className="card-link-wrap">
                        <div className="card-link d-flex align-items-center justify-content-center text-white">
                          See Project{" "}
                          <div className="ms-1 text-white">
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="divider row my-5 p-0">
          <img className="p-0" src={line2} alt="line1" />
        </div>

        <div className="container ImpactGame">
          <div className="row m-0 mt-5 align-items-center justify-content-between">
            <div className="col-lg-6 col-12  text">
              <div className="title second-responsive-title d-flex">
                <span className="me-4 number  p-0">2</span>
                <div className=" p-0">
                  <div>The Game Development Scene</div>
                </div>
              </div>
              <div className="col-12 d-lg-none d-block text-center">
                <img className="img-fluid" src={image2} alt="image2" />
              </div>
              <p className="smale-title mt-4">
                We aim to nurture the next generation of game developers in the
                region and is committed to helping the game development scene
                grow. We provide mentorship, training, and support to aspiring
                game developers and firmly believe in the power of games for
                change. Our dedication to the community goes beyond business as
                we take our social responsibility seriously and transfer
                knowledge and share our experience with others.
              </p>
            </div>
            <div className="col-5  d-none d-lg-block  text-center">
              <img className="img-fluid" src={image2} alt="image2" />
            </div>
          </div>
        </div>

        <div className="divider row my-5 p-0">
          <img className="p-0" src={line2} alt="line1" />
        </div>

        <div className="container ImpactGame">
          <div className="row m-0 mt-5 align-items-center">
            <div className="col-lg-6 col-12  text">
              <p className="title2 ">
                Our internship program for top Egyptian talent and game
                development competition prize winners.
              </p>
              <div className="col-12 d-lg-none d-block text-center">
                <img className="img-fluid" src={cat} alt="image2" />
                <p className="text-secondary">Project Cat Cafe</p>
              </div>
              <p className="smale-title mt-4">
                Cat cafe was a mobile cafe tycoon simulation that offered an
                internship program for top Egyptian talent and game development
                competition prize winners. The key challenge was to qualify
                intern students to develop a real market-ready product.
              </p>
            </div>
            <div className="col-6  d-none d-lg-block  text-center">
              <img className="img-fluid" src={cat} alt="image2" />
              <p className="text-secondary">Project Cat Cafe</p>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default ImpactGame;
