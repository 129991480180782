import "./App.scss";
import Header from "./components/header/header";
import RenderRoute from "./Routes/routes";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";

const App = ({ props }) => {
  return (
    <BrowserRouter>
      <header>
        <Header />
      </header>
      <main className="main">
        <div className="toasts" id="toasts">
          <div className="p-2">Your message has been sent successfully</div>
        </div>
        <div className="toasts-error" id="toast-error">
          <div className="p-2">Something went wrong please try again later</div>
        </div>
        <RenderRoute />
      </main>
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
};

export default App;
