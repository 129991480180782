import React from "react";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import DefaultView from "./defaultView";
import SingleWork from "./Single-Work/singleWork";

import "./ourWork.scss";
import AnimatedPages from "../Animation/AnimatedPages";
const OurWork = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <AnimatedPages>
      <div className="my-5 container">
        <div className="row">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path={`${path}`} component={DefaultView} />
              <Route path={`${path}/:child`} component={SingleWork} />
            </Switch>
          </AnimatePresence>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default OurWork;
