import React from "react";
import marketing from "../../../assets/What we do/marketing.png";

const Marketing = () => {
  return (
    <div className="container WhatWeDo my-6">
      <div className="row m-0  align-items-center">
        <div className="col-lg-6 col-12">
          <div className="title d-flex second-responsive-title">
            <span className="me-4 number">3</span>
            <p>Marketing and Advertising</p>
          </div>
          <div className="col-12 d-lg-none d-block text-center image">
            <img className="img-fluid" src={marketing} alt="marketing" />
            <p className="mt-3">
              <span>Pepsiman Cover Art</span>
            </p>
          </div>

          <p className="smale-title mt-4">
            Games in marketing create engaging experiences that build brand
            awareness, connect emotionally with consumers, and offer valuable
            insights. Phantasm empowers organisations to incorporate
            gamification into marketing campaigns, driving business results
            through memorable experiences and data collection. Games have been
            part of advertising since the early 20th century, providing a unique
            way to reach audiences. Pepsiman is a unique example of a marketing
            game, where the purpose of the game is to promote a product, in this
            case, Pepsi. From a marketing perspective, the game was a success
            because it generated a lot of buzz and attention for Pepsi in Japan
            during its release.
          </p>
        </div>
        <div className="col-6  d-none d-lg-block text-center image">
          <img className="w-50" src={marketing} alt="marketing" />
          <p className="mt-3">
            <span>Pepsiman Cover Art</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
