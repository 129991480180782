import React from "react";
import SIMTalent from "../../../assets/What we do/Education.png";

const Training = () => {
  return (
    <div className="container WhatWeDo my-6">
      <div className="row m-0  align-items-center">
        <div className="col-lg-6 col-12">
          <p className="title second-responsive-title">
            <span className="me-4 number">2</span>Training
          </p>
          <div className="col-12 d-lg-none d-block text-center image">
            <img className="img-fluid" src={SIMTalent} alt="SIMTalent" />
            <span>Training Platform Concept</span>
          </div>

          <p className="smale-title mt-4">
            We create engaging game-based solutions for effective training that
            enhances performance and satisfaction. Our solutions promote
            motivation, critical thinking, problem solving, and teamwork skills.
            With Phantasm, you can provide your employees with the tools they
            need to succeed and create a dynamic learning environment. We have a
            proven track record in game-based corporate, on-site, and machinery
            training solutions. Let us take your training efforts to the next
            level.
          </p>
        </div>
        <div className="col-6  d-none d-lg-block  text-center image">
          <img className="img-fluid" src={SIMTalent} alt="SIMTalent" />
          <span>Training Platform Concept</span>
        </div>
      </div>
    </div>
  );
};

export default Training;
