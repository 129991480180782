import React from "react";
import { useRouteMatch, NavLink } from "react-router-dom";
import AnimatedPages from "../Animation/AnimatedPages";
import light from "./../../assets/our work/0.png";
import savingGame from "./../../assets/our work/1.png";
import microGame from "./../../assets/our work/2.png";
import patrec from "./../../assets/our work/3.png";
import ecoEgypt from "./../../assets/our work/4.png";
import simTalant from "./../../assets/our work/5.png";
import apple from "./../../assets/our work/6.png";
const projects = [
  {
    id: "savingGame",
    Image: savingGame,
    name: "The Savings Game",
    text: " Partners: Deutsche Sparkassenstiftung für InternationaleKooperation, DSIK",
    icon1: null,
    icon2: "fas fa-mobile-alt",
    type: "Type: Educational Mobile Game",
    linkTo: `0`,
  },

  {
    id: "microGame",
    Image: microGame,
    name: "The Micro Business Game",
    text: " Partners: Deutsche Sparkassenstiftung für Internationale Kooperation; DSIK",
    icon1: null,
    icon2: "fas fa-mobile-alt",
    type: "Type: Educational Mobile Game",
    linkTo: `1`,
  },

  {
    id: "patrec",
    Image: patrec,
    name: "PatRec",
    text: " Partners: Holden Knight Healthcare",
    icon1: "fas fa-desktop",
    icon2: "fas fa-mobile-alt",
    type: "Type: Platform ",
    linkTo: `2`,
  },
  {
    id: "ecoEgypt",
    Image: ecoEgypt,
    name: "EcoEgypt",
    text: "Partners: UNDP / The Ministry of Environment in Egypt - EEAA/ Wali’s Studio ",
    icon1: "fas fa-desktop",
    icon2: null,
    type: "Type: Platform ",
    linkTo: `3`,
  },
  {
    id: "simTalant",
    Image: simTalant,
    name: "SIMTalent",
    text: "Partners: CELEMI ",
    icon1: "fas fa-desktop",
    icon2: null,
    type: "Type: Platform ",
    linkTo: `4`,
  },
  {
    id: "apple",
    Image: apple,
    name: "Apples and Oranges",
    text: "Partners: CELEMI ",
    icon1: "fas fa-desktop",
    icon2: null,
    type: "Type: Platform ",
    linkTo: `5`,
  },
];

const DefaultView = () => {
  const { url } = useRouteMatch();
  return (
    <AnimatedPages>
      <div className="DefaultView">
        <div className="col-12 d-flex align-items-end justify-content-center">
          <div className="img-wrapper">
            <img src={light} alt="light" className="img-fluid" />
          </div>
          <div>
            <h1 className="">Our Work</h1>
            <h6>A sample of our best work</h6>
          </div>
        </div>
        <div className="col-12 row m-0 mt-4 align-items-stretch test">
          {projects.map((project, index) => (
            <div className="col-lg-4 col-md-6 col-12 mt-4" key={index}>
              <div className="card text-center shadow p-3 pt-4 px-0 h-100">
                <NavLink
                  className="top-card-link"
                  to={`${url}/${project.linkTo}`}
                >
                  <img
                    className="card-img-top"
                    src={project.Image}
                    alt={project.id}
                  />
                  <div className="card-body px-4 pb-0">
                    <h5 className="card-title">{project.name}</h5>
                    <p style={{ minHeight: "36px" }} className="card-text mt-3">
                      {project.text}
                    </p>
                    <p className="icon">
                      <span>
                        <i className={project.icon1}></i>
                      </span>
                      {project.icon2 ? (
                        <span className="ms-1">
                          <i className={project.icon2}></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className="type">{project.type}</p>
                    <div className="card-link-wrap ">
                      <div className="card-link d-flex align-items-center justify-content-center">
                        See More{" "}
                        <div className="ms-1 text-white">
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
        <div className="row align-items-stretch ">
          <div className="col-lg-4 col-12 p-3"></div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default DefaultView;
