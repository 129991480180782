import React from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import ImpactGame from "../components/Impact-Game/ImpactGame";
import ContactUs from "../components/Contact-Us/ContactUs";
import Home from "../components/Home/Home";
import WhatWeDo from "../components/What-We-Do/WhatWeDo";
import OurWork from "../components/Our-Work/OurWork";
import { AnimatePresence } from "framer-motion";
const RenderRoute = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch key={location.pathname} location={location}>
        <Route exact path="/" component={Home} />
        <Route path="/our-work" component={OurWork} />
        <Route path="/what-we-do" component={WhatWeDo} />
        <Route path="/impact" component={ImpactGame} />
        <Route path="/contact-us" component={ContactUs} />
      </Switch>
    </AnimatePresence>
  );
};
export default RenderRoute;
