import React from "react";
import educationImg from "../../../assets/What we do/Microbusiness.png";

const Education = () => {
  return (
    <div className="container WhatWeDo my-6">
      <div className="row m-0  align-items-center">
        <div className="col-lg-6 col-12">
          <p className="title second-responsive-title">
            <span className="me-4 number">1</span>Education
          </p>
          <div className="col-12 d-lg-none d-block text-center image">
            <img className="img-fluid" src={educationImg} alt="Education" />
            <span>The Micro Business Game</span>
          </div>

          <p className="smale-title mt-4">
            We specialise in creating engaging educational games for schools,
            universities, and awareness programs. Our proven track record of
            delivering impactful solutions, through interactive learning
            experiences and custom awareness programs, has earned us the trust
            of educational organisations globally.
          </p>
        </div>
        <div className="col-6  d-none d-lg-block  text-center image">
          <img className="img-fluid" src={educationImg} alt="Education" />
          <span>The Micro Business Game</span>
        </div>
      </div>
    </div>
  );
};

export default Education;
