import React from "react";
import light from "../../../assets/What we do/light.png";

const WhatWeDoSection = () => {
  return (
    <div className="container WhatWeDo">
      <div className="row m-0 mt-3 align-items-center justify-content-between">
        <div className="col-lg-6 col-12  text">
          <p className="title responsive-title fw-bold">What We Do</p>
          <div className="col-12 d-lg-none d-block text-center">
            <img className="img-fluid" src={light} alt="light" />
          </div>
          <p className="smale-title mt-4">
            We love to help teach people new skills, we love to help businesses
            grow, we love to market and advertise for our clients.
          </p>
        </div>
        <div className="col-5  d-none d-lg-block  text-center">
          <img className="img-fluid" src={light} alt="light" />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;
