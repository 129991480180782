import React from "react";
import "./Footer.scss";
import footerLogo from "../../assets/footerLogo.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer mt-5">
      <div className="container-fluid">
        <div className="row m-0 align-items-center justify-content-between">
          <div className="col-lg-3 col-md-6 col-12 row m-0 align-items-center my-3 footer-logo">
            <img className="img-fluid" src={footerLogo} alt="footerLogo" />
          </div>
          <div className="col-lg-4 col-md-6 col-12 my-3 social d-flex justify-content-around">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/phantasm-solutions/mycompany/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/phantasmsolutions"
            >
              <i className="fab fa-facebook-square"></i>
            </a>

            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/phantasmsolutions/"
            >
              <i className="fab fa-instagram"></i>
            </a>

            <Link to="/contact-us">
              <i className="fas fa-envelope"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
