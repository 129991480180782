import React from "react";
import pepsiman from "../../../assets/What we do/pepsiman.png";

const Entertainment = () => {
  return (
    <div className="container WhatWeDo my-6">
      <div className="row m-0  align-items-center">
        <div className="col-lg-6 col-12">
          <div className="title second-responsive-title d-flex">
            <span className="me-4 number">4</span>
            <div>Entertainment</div>
          </div>
          <div className="col-12 d-lg-none d-block text-center image">
            <img className="img-fluid" src={pepsiman} alt="pepsiman" />
            <div className="d-flex justify-content-center">
              <p>Character sketches out of Historica Game Concept</p>
            </div>
          </div>
          <p className="smale-title mt-4">
            Phantasm seeks publishers to bring our high-quality indie game
            concepts, which hold our Arabic and Islamic values, to life. With a
            passionate veteran in game development on our team, we are dedicated
            to creating unique and engaging games that resonate with a wide
            audience. Our concepts are designed to promote cultural awareness
            and understanding while providing an immersive and entertaining
            gaming experience. Let us partner with you to deliver innovative and
            meaningful games that make a difference.
          </p>
        </div>
        <div className="col-6 d-none d-lg-block text-center image">
          <img className="img-fluid" src={pepsiman} alt="pepsiman" /> <br />
          <div className="d-flex justify-content-center ">
            <p>Character sketches out of Historica Game Concept</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entertainment;
