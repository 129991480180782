import React from "react";
import { Link } from "react-router-dom";
import light from "../../assets/Home/light.png";
import line1 from "../../assets/Home/line1.png";
import line2 from "../../assets/Home/line2.png";
import game1 from "../../assets/Home/game1.svg";
import game2 from "../../assets/Home/game2.svg";
import getInTouch from "../../assets/Home/getInTouch.png";
import AnimatedPages from "../Animation/AnimatedPages";
import HomeCarousel from "./HomeCarousel";
import "./Home.scss";

const Home = () => {
  let homeHeight = window.innerHeight;
  return (
    <AnimatedPages>
      <div className="container-fluid p-0">
        <div className=" home ">
          <div className="container home-container">
            <div
              className="home-row align-items-center"
              style={{
                minHeight: homeHeight - 67 < 570 ? "620px" : homeHeight - 67,
              }}
            >
              <div className="content col-12 row flex-wrap align-items-center m-0 justify-content-between">
                <div className="col-lg-5 col-12  text ">
                  <p className="title home-responsive-title">Phantasm</p>
                  <p className="title home-responsive-title">Solutions</p>
                  <p className="smale-title home-smale-title">
                    We specialise in creating engaging games and software
                    applications that bring your ideas to life.
                  </p>
                </div>
                <div className="col-lg-6 col-12 p-0 text-center">
                  <HomeCarousel />
                </div>
                <div className="col-12 text-center mt-3 align-self-end">
                  <p className="mb-0">
                    <a href="#what-we-do" className="text-white nav-link">
                      Scroll for more
                    </a>
                  </p>
                  <p className="position-relative">
                    <a href="#what-we-do" className="text-white arrow-down ">
                      <i className="fas fa-chevron-down"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div
            id="what-we-do"
            className="row m-0 mt-5 what-we-do align-items-center justify-content-between"
          >
            <div className="col-lg-6 col-12 text">
              <p className="title responsive-title fw-bold">What We Do</p>
              <div className="col-12 d-lg-none d-block text-center">
                <img className="img-fluid" src={light} alt="light" />
              </div>
              <p className="smale-title">
                We love to help teach people new skills, we love to help
                businesses grow, we love to market and advertise for our
                clients.
              </p>
              <p className="mt-5 know-more">
                <Link to="what-we-do">
                  Know More{" "}
                  <span className="ms-3">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </Link>
              </p>
            </div>
            <div className="col-5  d-none d-lg-block ">
              <img className="img-fluid" src={light} alt="light" />
            </div>
          </div>
        </div>

        <div className="divider row m-0 p-0 my-4">
          <img className="p-0" src={line1} alt="line1" />
        </div>

        <div className="container">
          <div
            id="what-we-do"
            className="row m-0 mt-5 what-we-do align-items-start"
          >
            <p className="title second-responsive-title fw-bold p-0 text-center">
              Impact
            </p>
            <div className="col-lg-6 col-12 row m-0 text justify-content-center">
              <div className="col-lg-9 col-12 text-center">
                <div className="col-12 ">
                  <img className="img-fluid" src={game1} alt="game1" />
                </div>
                <p className="mt-5 second-title"> Social Development</p>
                <p className="H-smale-title mt-4">
                  Dedicated to creating positive change in society is reflected
                  in everything we do. Helping organisations become more
                  effective and scale their impact.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 row m-0 text justify-content-center">
              <div className="col-lg-9 col-12 text-center">
                <div className="col-12 ">
                  <img className="img-fluid" src={game2} alt="game2" />
                </div>
                <div>
                  <p className="mt-4 second-title mb-0"> The Game</p>
                  <p className=" second-title"> Development Scene</p>
                </div>
                <p className="H-smale-title mt-4">
                  Nurturing the next generation of game developers in the region
                  and is committed to helping the game development scene grow.
                </p>
              </div>
            </div>
            <p className="mt-5 know-more text-center">
              <Link to="impact">
                Know More{" "}
                <span className="ms-3">
                  <i className="fas fa-chevron-right"></i>
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="divider row m-0 p-0 my-4">
          <img className="p-0" src={line2} alt="line1" />
        </div>

        <div className="container getIn">
          <div className="row m-0 justify-content-center ">
            <div className="col-12 row m-0 justify-content-center">
              {" "}
              <div className="col-lg-4 col-md-6 col-12 get-inTouch-image">
                <img
                  className="img-fluid"
                  src={getInTouch}
                  alt="get in touch"
                />
              </div>
            </div>
            <p className="text-center fw-bold p-0 mt-4 get-inTouch-text">
              Let’s Get in Touch!
            </p>
            <div className="text-center mb-5 get-inTouch-btn">
              <button className="btn">
                <Link to="/contact-us" className="nav-link">
                  {" "}
                  Contact Us
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};

export default Home;
