import React from "react";
import { NavLink } from "react-router-dom";
const navList = [
  {
    text: "Our Work",
    linkTo: "/our-work",
    activeClassName: "header-links-active",
    classNames: "fw-600 p-2 m-1  header-links",
  },
  {
    text: "Impact",
    linkTo: "/impact",
    activeClassName: "header-links-active",
    classNames: "fw-600 p-2 m-1 me-5 header-links",
  },
  {
    text: "Contact Us",
    linkTo: "/contact-us",
    activeClassName: "header-links-active",
    classNames: "fw-600 p-2 m-1  header-links",
  },
];
const NavLinks = ({ toggle, setToggle }) => {
  return (
    <>
      <div className="d-none d-lg-flex col-lg-8 d-flex align-items-center m-0 ">
        <ul className="col-12 ps-5 navbar-nav flex-row  justify-content-around">
          <li className="nav-item">
            <NavLink
              to={"/what-we-do"}
              activeClassName={"header-links-active"}
              className={"fw-600 p-2 m-1  header-links "}
            >
              What we do
            </NavLink>
          </li>
          {navList.map((link, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                to={link.linkTo}
                activeClassName={link.activeClassName}
                className={link.classNames}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <div className="d-xl-none d-lg-none d-xl-flex col-5 p-0 text-end me-2 ">
        <button onClick={() => setToggle(!toggle)} className="btn-icon">
          <i className="fas fa-bars"></i>
        </button>
      </div>
    </>
  );
};
export const SideNav = ({ toggle, setToggle }) => {
  return (
    <ul className="col-12 navbar-nav flex-column  justify-content-startr sideNav-for-Home">
      <li className="nav-item m-2">
        <NavLink
          onClick={() => setToggle(!toggle)}
          exact
          to={"/what-we-do"}
          activeClassName={"header-links-active"}
          className={"fw-600 p-2 m-1 me-5 header-links "}
        >
          What we do
        </NavLink>
      </li>
      {navList.map((link, index) => (
        <li className="nav-item m-2" key={index}>
          <NavLink
            onClick={() => setToggle(!toggle)}
            to={link.linkTo}
            activeClassName={link.activeClassName}
            className={link.classNames}
          >
            {link.text}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
export default NavLinks;
